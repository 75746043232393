<template>
  <header class="header">
<!--    <div class="header__top-wrapper">-->
<!--      <div class="header__top">-->
<!--        <p class="header__slogan">Болеем за наших!</p>-->
<!--        <a href="#" class="olymp-link">Олимпиада 2020</a>-->
<!--      </div>-->
<!--    </div>-->
    <div class="header__bottom-wrapper">
      <div class="header__bottom">
        <div class="header__menu-wrapper" :class="{'header-fixed':burgerMenu}">
          <button class="menu-btn" @click="burgerMenu = !burgerMenu">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div class="header__menu">
            <div class="town">
              <v-dialog
                  hide-overlay
                  width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      text
                  >
                    <a @click="notice.isPopup = 11">{{ `${client.clientCity.length ? client.clientCity[0].text : 'Нажмите, чтобы указать город'}` }}</a>
                  </v-btn>
                </template>
                <card>asdasd</card>
              </v-dialog>
            </div>
            <ul class="reg-list">
              <li class="reg-list__item signup-open"><a href="#">Регистрация</a></li>
              <li class="reg-list__item signin-open"><a href="#">Вход</a></li>
            </ul>
            <a href="#" class="trust-link">Почему ремонт доверяют нам?</a>
            <div class="calc-link">
              <a href="#">
                <span>Рассчитать ремонт</span>
              </a>
            </div>
            <div class="phone-wrap">
              <div class="phone-icons">
                <a href="#" class="whatsapp">
                  <img src="../assets/images/icon-whatsapp.svg" alt="whatsapp" width="28" height="28">
                  <span class="visually-hidden">whatsapp</span>
                </a>
                <a href="#" class="viber">
                  <img src="../assets/images/icon-viber.svg" alt="viber" width="28" height="28">
                  <span class="visually-hidden">viber</span>
                </a>
              </div>
              <a href="tel:88001234567" class="tel">8 800 123-45-67</a>
            </div>
          </div>
        </div>
        <div class="header__left">
          <router-link to="/" class="header__menu-logo"><img src="../assets/images/logo.svg" alt="logo" width="155" height="24"></router-link>
          <div class="town town-big">
            <v-menu
                :close-on-content-click="false"
                :nudge-width="200"
                v-model="notice.popupCity"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="searchCity"
                    text
                >
                  <a @click="notice.isPopup = 11">
                    {{ `${client.clientCity.length ? client.clientCity[0].text : 'Нажмите, чтобы указать город'}` }}
                  </a>
                </v-btn>
              </template>
              <v-card>
                <v-autocomplete
                    dense
                    filled
                    :items="client.clientCity"
                    v-model="setCity"
                    :placeholder="client.clientCity.length ? client.clientCity[0].text : 'Нажмите, чтобы указать город'"
                ></v-autocomplete>
              </v-card>
            </v-menu>

          </div>
        </div>
        <div class="header__right">
          <div class="user">
            <div class="user__enter">
              <v-dialog
                  v-model="notice.dialogAuth"
                  width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                      style="text-transform: initial"
                      v-show="!AUTH"
                  >
                    <v-icon color="red" class="mr-2">mdi-account</v-icon>
                    Войти
                  </v-btn>
                </template>
                <v-card class="pa-10">
                  <div id="recaptcha"></div>
                  <v-form v-model="auth.validate">
                    <v-text-field
                        label="Телефон"
                        outlined
                        v-bind:return-masked-value="true"
                        mask="##-##-####"
                        :rules="[auth.rules.phone]"
                        v-model="auth.form.phone"
                        @keydown.enter.prevent="auth.validate && login()"
                    ></v-text-field>
                    <v-card-actions class="ml-auto d-table">
                      <v-btn
                          rounded
                          color="red"
                          style="color: white"
                          width="150px"
                          @click="notice.dialogAuth = false; notice.dialogReg = true"
                          v-if="1===2"
                      >Регистрация</v-btn>
                      <v-btn
                          rounded
                          color="red"
                          style="color: white"
                          width="150px"
                          :disabled="!auth.validate"
                          @click="login"
                          @keydown.enter.prevent="login"
                      >
                        {{!auth.form.progress ? 'Войти' : ''}}
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-3"
                          v-if="auth.form.progress"
                        ></v-progress-circular>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
                <v-snackbar
                    v-model="auth.snackbar"
                >
                  Неверный логин или пароль

                  <template v-slot:action="{ attrs }">
                    <v-btn
                        color="pink"
                        text
                        v-bind="attrs"
                        @click="notice.snackbar = false"
                    >
                      Закрыть
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-dialog>
              <v-menu offset-y v-if="AUTH">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    {{$store.state.phoneNumber}}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <router-link :to="'/sto'">
                      <v-btn
                          text
                          style="text-transform: initial"
                      >
                        <v-icon color="red" class="mr-2">mdi-account-box</v-icon>
                        Личный кабинет
                      </v-btn>
                    </router-link>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                        text
                        style="text-transform: initial"
                        @click.stop="goOut"
                    >
                      <v-icon color="red" class="mr-2">mdi-account-remove</v-icon>
                      Выход
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <ul class="enter-list">
              <li class="enter-list__item">
                <a href="#">
                  <span class="enter-list__number">1</span>
                  <img src="../assets/images/icon-notif.svg" alt="notif" width="18" height="18">
                </a>
              </li>
              <li class="enter-list__item">
                <a href="#">
                  <span class="enter-list__number">4</span>
                  <img src="../assets/images/icon-message.svg" alt="message" width="18" height="18">
                </a>
              </li>
              <li class="enter-list__item enter-list__close"><a href="#">Выйти</a></li>
            </ul>
          </div>
          <div class="count-link"><a v-scroll-to="'#remont'"><span class="count-link__logo"><img src="../assets/images/icon-calc2.svg" alt="calculate" width="16" height="16"></span><span class="count-link__text">Рассчитать ремонт</span></a></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import axios from "axios";
  import { mapActions, mapGetters } from 'vuex'
  import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
  export default {
    name: 'Header',
    data: () => ({
      client: {
        clientCity: [],
        clientRegion: null,
      },
      auth: {
        form: {
          phone: null,
          password: null,
          code: null,
          confirmationResult: false,
          progress: false
        },
        validate: false,
        rules: {
          email: [ v => !!v || 'Введите email' ],
          password: [ v => !!v || 'Введите пароль' ],
          phone: value => !value ? 'Обязательное поле'
            : !((+value || value[0] === '+') && value[0] === '+') ? 'Формат +79999999999'
              : (value && value.length === 12) ? !(+value)?'Формат +79999999999':true
                : !(value && value.length >= 12) ? 'Не менее 11 цифр'
                  : !(value && value.length <= 12) ? 'Не более 11 цифр' : true
        },
        snackbar: false
      },
      registration: {
        form: {
          email: null,
          password: null,
          repeatPassword: null
        },
        validate: false,
        show: false,
        snackbar: false
      },

      registrationRules: {
        email: v => !!(v || '').match(/@/) || 'Пожалуйста, введите действительный адрес электронной почты',
        length: len => v => (v || '').length >= len || `Недопустимая длина символа, обязательно ${len}`,
        password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
          'Пароль должен содержать заглавную букву, числовой символ и специальный символ.',
        repeatPassword: (v, e) => v === e || 'Пароли не совпадают',
        required: v => !!v || 'Это поле обязательно к заполнению',
        phone: value => !value ? 'Обязательное поле'
          : !(typeof value !== 'number') ? 'Введите только цифры'
            : !(value && value.length >= 11) ? 'Не менее 11 цифр'
              : !(value && value.length <= 11) ? 'Не более 11 цифр' : true
        // phone: value => !!value || 'Обязательное поле' (typeof value !== 'number') || 'Введите только цифры' ||
        // (value && value.length >= 11) || 'Не менее 11 цифр' || (value && value.length <= 11) || 'Не более 11 цифр'
      },
      setCity: null,
      notice: {
        isPopup: null,
        popupCity: false,
        title: null,
        subtitle: null,
        dialogAuth: false,
        dialogReg: false,
      },
      geocoder: null,
      burgerMenu: false
    }),
    watch: {
      setCity: function (val) {
        console.log(val)
        if (val) {
          this.notice.popupCity = false
          this.client.clientCity = [{text: val, id: Math.random() * 4}]
          localStorage.clientCity = val
          this.client.clientRegion = ''
          localStorage.clientRegion = ''
        } else return false
      }
    },
    computed: {
      ...mapGetters([
        'AUTH'
      ])
    },
    methods: {
      ...mapActions([
        'LOGIN',
        'LOGOUT'
      ]),
      async registr () {
        const data = {
          email: this.registration.form.email,
          password: this.registration.form.password,
          repeatPassword: this.registration.form.repeatPassword
        }
        await this.REGISTRATION(data)
        if (this.AUTH) {
          this.notice.dialogReg = false
          this.registration.form.email = null
        } else {
          this.registration.validate = false
          this.registration.form.email = null
          this.registration.snackbar = true
        }
      },
      login () {
        this.auth.form.progress = true
        const auth = getAuth();
        const vm = this
        let phoneNumber = this.auth.form.phone;

        let appVerifier = new RecaptchaVerifier('recaptcha', {
          'size': 'invisible',
          'callback': (response) => {
          },
          'expired-callback': (err) => {
          }
        }, auth);
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            console.log("Login success", confirmationResult);
            appVerifier.clear();
            let code = prompt('Введите код из смс')
            return confirmationResult.confirm(code).then((result) => {
              // User signed in successfully.
              const user = result.user;
              let userdata = {
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                isAnonymous: user.isAnonymous,
                metadata: user.metadata,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                uid: user.uid,
              }
              vm.LOGIN(userdata)
              vm.notice.dialogAuth = false
              // ...
            }).catch((error) => {
              console.log('error - ' + error)
              vm.auth.form.progress = true
            });
          }).catch(function (error) {
            console.log(error)
            vm.auth.form.progress = true
          });
      },
      goOut () {
        this.LOGOUT()
        this.$route.path !== '/' && this.$router.replace('/')
      },
      async getCity () {
        if (localStorage.clientCity) {
          this.client.clientCity.push({text: localStorage.clientCity, id: 1293812398})
          this.client.clientRegion = localStorage.clientRegion
          this.$route.params.clientCity = localStorage.clientCity
          this.notice.title = 'Ваш город ' + localStorage.clientCity
          this.notice.subtitle = localStorage.clientRegion? 'Ваш регион ' + localStorage.clientRegion : null
        } else {
          this.notice.title = `Ваш город Москва`
          this.$route.params.clientCity = 'Москва'
          this.client.clientCity = 'Москва'
          localStorage.clientCity = this.client.clientCity
          this.notice.isPopup = 11 // чтоб показать попап notice
          // await navigator.geolocation.getCurrentPosition((position) => {
          //   // console.log(google)
          //   // eslint-disable-next-line
          //   var city
          //   // if (typeof google === 'undefined') window.location = '/'
          //   // eslint-disable-next-line
          //   let geocoder = new google.maps.Geocoder()
          //   // eslint-disable-next-line
          //   geocoder.geocode({
          //     'location': {
          //       lat: position.coords.latitude,
          //       lng: position.coords.longitude
          //     }
          //   }, (results, status) => {
          //     localStorage.clientCity = results[0].address_components[2].long_name
          //     localStorage.clientRegion = results[0].address_components[4].long_name
          //     this.$route.params.clientCity = results[0].address_components[2].long_name
          //     this.client.clientCity = results[0].address_components[2].long_name
          //     this.client.clientRegion = results[0].address_components[4].long_name
          //     this.notice.title = `Ваш город ${this.client.clientCity}`
          //     this.notice.subtitle = `Ваш регион ${this.client.clientRegion}`
          //     this.notice.isPopup = 11 // чтоб показать попап notice
          //     console.log('status')
          //   }, error => {
          //     console.log('status')
          //     if (error) {
          //       this.notice.title = `Ваш город Москва`
          //       this.$route.params.clientCity = 'Москва'
          //       this.client.clientCity = 'Москва'
          //       localStorage.clientCity = this.client.clientCity
          //       this.notice.isPopup = 11 // чтоб показать попап notice
          //     }
          //   })
          // }, error => {
          //   if (error) {
          //     this.notice.title = `Ваш город Москва`
          //     this.$route.params.clientCity = 'Москва'
          //     this.client.clientCity = 'Москва'
          //     localStorage.clientCity = this.client.clientCity
          //     this.notice.isPopup = 11 // чтоб показать попап notice
          //   }
          // })
        }
      },
      searchCity () {
        const formData = new FormData()
        formData.append('_type', 'query')
        formData.append('lang', 'ru')
        axios.post('https://crm.v-avtoservice.com/list/site-get-cities',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(res => this.client.clientCity.push.apply(this.client.clientCity, res.data))
      },
      removePopup () {
        this.notice.isPopup = -1 // закрываем попап
      },
    },
    mounted() {
      this.getCity()
    }
  }
</script>
<style>
   .v-aplication .v-btn__content a {
     color: white!important;
   }
   a {
     text-decoration: none;
   }
</style>
