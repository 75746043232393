<template>
  <v-app>
    <vHeader/>
    <breadcrumbs />
    <v-main>
      <router-view/>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
  import vHeader from './components/v-header'
  import Footer from './components/Footer'
  import Breadcrumbs from "./components/Breadcrumbs";
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import {getDatabase, onValue, ref, set} from "firebase/database";
  import {mapActions} from 'vuex'
export default {
  name: 'App',
  components: {
    Breadcrumbs,
    vHeader,
    Footer
  },

  data: () => ({
    //
  }),
  computed: {
    // checkPageNotFound () {
    //   return this.$route.name !== 'Страница не найдена' && this.$route.name !== '404'
    // }
  },
  methods: {
    ...mapActions([
      'AUTH_CHECK'
    ]),
    async writeUserData(data) {
      const auth = getAuth();
      await auth.onAuthStateChanged(function(user) {
        console.log(user)
        if (user) {
          console.log(user)

          console.log('сохранение...')
          const db = getDatabase();
          set(ref(db, '/'), {
            data
          });
        } else {
          console.log('необходимо войти')
        }
        // ...
      })
    },
  },
  mounted() {
    if (!localStorage.userdata) {
      this.AUTH_CHECK()
    }
  }
};
</script>

<style>
  @import "./assets/css/normalize.css";
  @import "./assets/libs/owl-carousel2/assets/owl.carousel.min.css";
  @import "./assets/libs/owl-carousel2/assets/owl.theme.default.min.css";
  @import "./assets/css/main.css";
  html { overflow-y: auto }
</style>
