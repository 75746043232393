import Vue from 'vue'
import App from './App.vue'
import firebase from "firebase/compat";
import store from './store/store'
import router from './router'
import vuetify from './plugins/vuetify';
import VueScrollTo from 'vue-scrollto'

Vue.config.productionTip = false
Vue.use(VueScrollTo)

firebase.initializeApp({
  apiKey: "AIzaSyC_AWl70HjS-XaodbcCtTLBe44okOM547w",
  authDomain: "v-avtoservice.firebaseapp.com",
  databaseURL: "https://v-avtoservice-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "v-avtoservice",
  storageBucket: "v-avtoservice.appspot.com",
  messagingSenderId: "654875854664",
  appId: "1:654875854664:web:d398c0fa1cf96ccc9d957d",
  measurementId: "G-4Q5FP1JG3N"
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    /*
    этот путь требует авторизации, проверяем залогинен ли
    пользователь, и если нет, запрещаем доступ к странице
     */
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        next()
      } else {
        console.log('No user is signed in.')
        next({
          path: '/404'
        })
      }
    })
  } else {
    next() // всегда так или иначе нужно вызвать next()!
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
