<template>
    <footer class="footer">
        <div class="footer__top">
            <a href="#" class="footer__logo"><img src="../assets/images/logo.svg" alt="logo" width="182" height="28"></a>
            <ul class="appstore-list">
                <li class="appstore-list__item appstore"><a href="https://apps.apple.com/ru/app/id1469217999"><img src="../assets/images/footer/appstore.svg" alt="appstore" width="138" height="38"></a></li>
                <li class="appstore-list__item googleplay"><a href="https://play.google.com/store/apps/details?id=in_car_carecenter.rf"><img src="../assets/images/footer/googleplay.svg" alt="googleplay" width="131" height="38"></a></li>
            </ul>
            <nav class="footer__nav nav">
                <div class="nav__wrapper">
                    <div class="nav__list-wrapper">
                        <h3 class="nav__list-title">Автовладельцам</h3>
                        <ul class="nav__list">
                            <li class="nav__item"><a href="#">Все марки авто</a></li>
                            <li class="nav__item"><a href="#">Почему ремонт у нас дешевле</a></li>
                            <li class="nav__item">Все сервисы<a href="#"></a></li>
                            <li class="nav__item">Советы по ремонту авто<a href="#"></a></li>
                        </ul>
                    </div>

                    <div class="nav__list-wrapper">
                        <h3 class="nav__list-title">Партнёрские программы</h3>
                        <ul class="nav__list">
                            <li class="nav__item"><router-link :to="{name: 'Подключить автосервис'}" @click="dialog.regSto = true">Подключить автосервис</router-link></li>
                            <li class="nav__item"><a href="#">Поставщикам запчастей</a></li>
                            <li class="nav__item"><a href="#">Автомобильным сайтам</a></li>
                        </ul>
                    </div>

                    <div class="nav__list-wrapper" style="margin-right: 140px;">
                        <h3 class="nav__list-title">Информация</h3>
                        <ul class="nav__list">
                            <li class="nav__item"><a href="#">Обслуживание автопарков</a></li>
                            <li class="nav__item"><a href="#">Пользовательское соглашение <br> Для СМИ</a></li>
                        </ul>
                    </div>

                    <div class="nav__list-wrapper">
                        <h3 class="nav__list-title">Сотрудничество</h3>
                        <ul class="nav__list">
                            <li class="nav__item"><a href="#">Открыть “В-Автосервис” в своём <br> городе</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <div class="footer__bottom">
            <div class="footer__bottom-wrapper">
                <ul class="footer__bottom-list">
                    <li><a href="#"><img src="../assets/images/flag-ukr.png" alt="ukr" width="25" height="25"></a></li>
                    <li><a href="#"><img src="../assets/images/flag-bel.png" alt="bel" width="25" height="25"></a></li>
                </ul>
            </div>
        </div>
        <v-dialog
            v-model="dialog.regSto"
            width="500"
        >
            <v-card class="pa-10">
                <v-form v-model="registration.validate">
                    <v-text-field
                        label="Email"
                        outlined
                        :rules="[registrationRules.email]"
                        v-model="registration.form.email"
                    ></v-text-field>
                    <v-text-field
                        label="Пароль"
                        outlined
                        :rules="[registrationRules.password]"
                        v-model="registration.form.password"
                        @click:append="registration.show = !registration.show"
                        :append-icon="registration.show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="registration.show ? 'text' : 'password'"
                    ></v-text-field>
                    <v-text-field
                        label="Повторите пароль"
                        outlined
                        :rules="[registrationRules.repeatPassword(registration.form.password, registration.form.repeatPassword)]"
                        v-model="registration.form.repeatPassword"
                        @click:append="registration.show = !registration.show"
                        :append-icon="registration.show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="registration.show ? 'text' : 'password'"
                    ></v-text-field>
                    <v-card-actions class="ml-auto d-table">
                        <v-btn
                            rounded
                            color="red"
                            style="color: white"
                            width="150px"
                            :disabled="!registration.validate"
                            @click="registr"
                        >
                            Регистрация
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
            <v-snackbar
                v-model="registration.snackbar"
            >
                Этот email уже используется

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="pink"
                        text
                        v-bind="attrs"
                        @click="registration.snackbar = false"
                    >
                        Закрыть
                    </v-btn>
                </template>
            </v-snackbar>
        </v-dialog>
    </footer>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Footer",
        data () {
            return {
                dialog: {
                    regSto: false
                },
                registration: {
                    form: {
                        email: null,
                        password: null,
                        repeatPassword: null
                    },
                    validate: false,
                    show: false,
                    snackbar: false
                },

                registrationRules: {
                    email: v => !!(v || '').match(/@/) || 'Пожалуйста, введите действительный адрес электронной почты',
                    length: len => v => (v || '').length >= len || `Недопустимая длина символа, обязательно ${len}`,
                    password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
                        'Пароль должен содержать заглавную букву, числовой символ и специальный символ.',
                    repeatPassword: (v, e) => v === e || 'Пароли не совпадают',
                    required: v => !!v || 'Это поле обязательно к заполнению',
                },
            }
        },
        computed: {
            ...mapGetters([
                'AUTH'
            ])
        },
        methods: {
            ...mapActions([
                'REGISTRATION',
            ]),
            async registr () {
                const data = {
                    email: this.registration.form.email,
                    password: this.registration.form.password,
                    repeatPassword: this.registration.form.repeatPassword,
                    sto: true
                }
                await this.REGISTRATION(data)
                if (this.AUTH) {
                    this.dialog.regSto = false
                    this.registration.form.email = null
                } else {
                    this.registration.validate = false
                    this.registration.form.email = null
                    this.registration.snackbar = true
                }
            },
        }
    }
</script>

<style scoped>

</style>
