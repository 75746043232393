import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/v-home'
import diagnostika from '../components/pages/diagnostika'
import Service from '../components/pages/service'
import pageNotFound from '../components/404'
import sto from '../components/lk/sto/index'
import connectAutoService from '../components/pages/Connect-autoservice'

Vue.use(VueRouter)


const router = new VueRouter({

  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/diagnostika',
      name: 'Диагностика',
      component: diagnostika
    },
    {
      path: '/service/:id',
      name: 'Service',
      component: Service,
      props: true
    },
    {
      path: '/sto',
      name: localStorage.user? (JSON.parse(localStorage.user).sto ? 'Личный кабинет СТО' : 'Страница не найдена'): 'Страница не найдена',
      component:  localStorage.user? (JSON.parse(localStorage.user).sto ? sto  :  pageNotFound) : pageNotFound,
    },
    {
      path: '/connection',
      name: 'Подключить автосервис',
      component: connectAutoService
    },
    {
      path: '/404',
      name: '404',
      component: pageNotFound
    }, {
      path: '*',
      redirect: '/404'
    }
  ],
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  params: true

})

export default router
