<template>
  <section class="service">
    <section class="offer offer-service">
      <div class="offer__wrapper offer__wrapper-service">
        <div class="offer__text offer__text-service">
          <h2 class="offer__title">{{$route.params.name}}</h2>
          <div class="offer__service-p">Подберём лучший сервис</div>
        </div>
      </div>
    </section>

    <section class="form-section">
      <div class="form-section__wrapper">
        <h2 class="section-title">Подберём сервис за 7 минут. Просто заполни форму</h2>
        <Form/>
      </div>
    </section>

    <section v-if="1===2" class="service-shop">
      <header class="service-shop__header">
        <h2 class="section-title">Предложения с фиксированными ценами</h2>
        <div class="service-shop__choose">
          <span>По цене:</span>
          <div class="service-shop__btn-toggle">
            <button class="block service-shop-toggle service-cheap">дешевле</button>
            <button class="service-shop-toggle service-expensive">дороже</button>
            <div class="service-shop__btn-wrapper">
              <button class="service-shop__cheap">дешевле</button>
              <button class="service-shop__expensive">дороже</button>
            </div>
          </div>
        </div>
      </header>
      <div class="service-shop__wrapper">
        <div class="service-shop__list">
          <div class="service-shop__item">
            <div class="service-shop__left">
              <div class="service-shop__title-wrapper">
                <h3 class="service-shop__title">Диагностика</h3>
                <h3 class="service-shop__title">Диагностика кондиционера</h3>
              </div>
              <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
            </div>
            <div class="service-shop__right">
              <div class="service-shop__price">500 руб</div>
              <a href="#" class="service-shop__link entry-link">Записаться</a>
            </div>
          </div>

          <div class="service-shop__item">
            <div class="service-shop__left">
              <div class="service-shop__title-wrapper">
                <h3 class="service-shop__title">Тормозная система</h3>
                <h3 class="service-shop__title">Замена тормозных колодок</h3>
              </div>
              <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
            </div>
            <div class="service-shop__right">
              <div class="service-shop__price">1200 руб</div>
              <a href="#" class="service-shop__link entry-link">Записаться</a>
            </div>
          </div>

          <div class="service-shop__item">
            <div class="service-shop__left">
              <div class="service-shop__title-wrapper">
                <h3 class="service-shop__title">Стёкла, фары</h3>
                <h3 class="service-shop__title">Замена заднего стекла</h3>
              </div>
              <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
            </div>
            <div class="service-shop__right">
              <div class="service-shop__price">1800 руб</div>
              <a href="#" class="service-shop__link entry-link">Записаться</a>
            </div>
          </div>

          <div class="service-shop__big">
            <div class="service-shop__item">
              <div class="service-shop__left">
                <div class="service-shop__title-wrapper">
                  <h3 class="service-shop__title">Диагностика</h3>
                  <h3 class="service-shop__title">Диагностика кондиционера</h3>
                </div>
                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
              </div>
              <div class="service-shop__right">
                <div class="service-shop__price">500 руб</div>
                <a href="#" class="service-shop__link entry-link">Записаться</a>
              </div>
            </div>

            <div class="service-shop__item">
              <div class="service-shop__left">
                <div class="service-shop__title-wrapper">
                  <h3 class="service-shop__title">Тормозная система</h3>
                  <h3 class="service-shop__title">Замена тормозных колодок</h3>
                </div>
                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
              </div>
              <div class="service-shop__right">
                <div class="service-shop__price">1200 руб</div>
                <a href="#" class="service-shop__link entry-link">Записаться</a>
              </div>
            </div>

            <div class="service-shop__item">
              <div class="service-shop__left">
                <div class="service-shop__title-wrapper">
                  <h3 class="service-shop__title">Стёкла, фары</h3>
                  <h3 class="service-shop__title">Замена заднего стекла</h3>
                </div>
                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
              </div>
              <div class="service-shop__right">
                <div class="service-shop__price">1800 руб</div>
                <a href="#" class="service-shop__link entry-link">Записаться</a>
              </div>
            </div>

            <div class="service-shop__item">
              <div class="service-shop__left">
                <div class="service-shop__title-wrapper">
                  <h3 class="service-shop__title">Диагностика</h3>
                  <h3 class="service-shop__title">Диагностика кондиционера</h3>
                </div>
                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
              </div>
              <div class="service-shop__right">
                <div class="service-shop__price">500 руб</div>
                <a href="#" class="service-shop__link entry-link">Записаться</a>
              </div>
            </div>

            <div class="service-shop__item">
              <div class="service-shop__left">
                <div class="service-shop__title-wrapper">
                  <h3 class="service-shop__title">Тормозная система</h3>
                  <h3 class="service-shop__title">Замена тормозных колодок</h3>
                </div>
                <p class="service-shop__annotation">Здесь будет описан комментарий к указанной фиксированной цене</p>
              </div>
              <div class="service-shop__right">
                <div class="service-shop__price">1200 руб</div>
                <a href="#" class="service-shop__link entry-link">Записаться</a>
              </div>
            </div>
          </div>
        </div>
        <a href="#" class="service__shop-all"><span>Получить </span>Все предложения</a>
      </div>
    </section>

    <section class="service-info">
      <div class="service-info__wrapper">
        <div class="service-info__text">
          <div v-html="text"></div>
        </div>
        <div class="service-info__img">
          <img src="../../assets/images/service-phone.png" alt="phone" width="187" height="180">
        </div>
      </div>
    </section>

    <article class="comment">
      <div class="comment__add-wrapper">
        <h2 class="section-title">Истории наших клиентов</h2>
        <a href="#" class="comment__add">добавить свою</a>
      </div>
      <Carousel/>
    </article>

    <section class="stocks">
      <h2 class="section-title">Акции для любимых клиентов</h2>
      <div class="stocks__chapter owl-carousel owl-theme" id="stocksCarousel">
        <div class="stocks__wrapper stocks__wrapper-one">
          <h3 class="stocks__title">Подарок: 3 л масла при ремонте двигателя на сумму от 20 000 руб</h3>
          <p class="stocks__condition">действует до 01.01.2019</p>
        </div>
        <div class="stocks__wrapper stocks__wrapper-two">
          <h3 class="stocks__title">Легендарная тормозная система Brembo всего за 23 500 руб</h3>
          <p class="stocks__condition">действует до 01.01.2019</p>
        </div>
        <div class="stocks__wrapper stocks__wrapper-three">
          <h3 class="stocks__title">Скидка 20% на любую оклейку авто плёнкой Brandname</h3>
          <p class="stocks__condition">действует до 01.01.2019</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import vForm from '../form'
import axios from "axios";
import Carousel from '../Carousel'
export default {
  name: "Service",
  components: {
    'Form': vForm,
    'Carousel': Carousel
  },
  data () {
    return {
      category: [],
      text: null,
      brand: [
        { name: 'Aston Martin', abbr: 'AS', id: 2 },
        { name: 'Audi', abbr: 'AU', id: 3 },
        { name: 'Bentley', abbr: 'BE', id: 4 },
        { name: 'BMW', abbr: 'BM', id: 5 },
        { name: 'Brilliance', abbr: 'BR', id: 6 },
        { name: 'УАЗ', abbr: 'УА', id: 7 },
        { name: 'Cadillac',abbr: 'CA', id: 2 },
        { name: 'Changan',abbr: 'CHA', id: 2 },
        { name: 'Chery',abbr: '', id: 2 },
        { name: 'Chevrolet',abbr: 'CHE', id: 2 },
        { name: 'Chrysler',abbr: 'Ch', id: 2 },
        { name: 'Citroen',abbr: 'Cit', id: 2 },
        { name: 'Datsun',abbr: 'Da', id: 2 },
        { name: 'DongFeng',abbr: 'DO', id: 2 },
        { name: 'DS',abbr: 'DS', id: 2 },
        { name: 'FAW',abbr: 'FA', id: 2 },
        { name: 'Ferrari',abbr: 'FE', id: 2 },
        { name: 'Fiat', abbr: 'FI', id: 2 },
        { name: 'Ford', abbr: 'Fo', id: 2 },
        { name: 'Foton', abbr: 'Fo', id: 2 },
        { name: 'Geely', abbr: 'Ge', id: 2 },
        { name: 'Genesis', abbr: 'Ge', id: 2 },
        { name: 'Great Wall', abbr: 'Gr', id: 2 },
        { name: 'Haval', abbr: 'HA', id: 2 },
        { name: 'Hawtai', abbr: 'HAW', id: 2 },
        { name: 'Honda', abbr: 'HO', id: 2 },
        { name: 'Hyundai', abbr: 'HY', id: 2 },
        { name: 'Infiniti', abbr: 'IN', id: 2 },
        { name: 'Isuzu', abbr: 'IS', id: 2 },
        { name: 'JAC', abbr: 'JA', id: 2 },
        { name: 'Jaguar', abbr: 'JA', id: 2 },
        { name: 'Jeep', abbr: 'JE', id: 2 },
        { name: 'Kia', abbr: 'KI', id: 2 },
        { name: 'Lamborghini', abbr: 'LAM', id: 2 },
        { name: 'Land Rover', abbr: 'LA', id: 2 },
        { name: 'Lexus', abbr: 'LE', id: 2 },
        { name: 'Lifan', abbr: 'LI', id: 2 },
        { name: 'Maserati', abbr: 'MA', id: 2 },
        { name: 'Mazda', abbr: 'MA', id: 2 },
        { name: 'Mercedes-Benz', abbr: 'ME', id: 2 },
        { name: 'Mini', abbr: 'MI', id: 2 },
        { name: 'Mitsubishi', abbr: 'MIT', id: 2 },
        { name: 'Nissan', abbr: 'NI', id: 2 },
        { name: 'Peugeot', abbr: 'PE', id: 2 },
        { name: 'Porsche', abbr: 'PO', id: 2 },
        { name: 'Renault', abbr: 'REN', id: 2 },
        { name: 'Rolls-Royce', abbr: 'RO', id: 2 },
        { name: 'Skoda', abbr: 'SK', id: 2 },
        { name: 'Smart', abbr: 'SM', id: 2 },
        { name: 'SsangYong', abbr: 'SA', id: 2 },
        { name: 'Subaru', abbr: 'SU', id: 2 },
        { name: 'Suzuki', abbr: 'SUZ', id: 2 },
        { name: 'Toyota', abbr: 'TOY', id: 2 },
        { name: 'Volkswagen', abbr: 'VOL', id: 2 },
        { name: 'Volvo', abbr: 'VO', id: 2 },
        { name: 'Zotye', abbr: 'ZO', id: 2 },
        { name: 'ВАЗ (Lada)', abbr: 'LAВА', id: 2 },
        { name: 'ГАЗ', abbr: 'ГА', id: 2 },
      ],
    }
  },
  created() {
    axios.get('https://crm.v-avtoservice.com/list/site-get-category?link=' + this.$route.params.id + '&city=5097')
      .then(response => {
        this.category = response.data
        this.text = '<div>' + response.data.text.replace(/{form_application}/gi, '<fps :user.sync="user" :city.sync="city" :auth.sync="auth" :fixprice.sync="fixprice"></fps><index-form :user.sync="user" :city.sync="city" :auth.sync="auth"/>').replace(/{form_cat}/gi, '<fps :user.sync="user" :city.sync="city" :auth.sync="auth" :fixprice.sync="fixprice"></fps><index-form :user.sync="user" :city.sync="city" :auth.sync="auth"/>').replace(/{form_shinomontag}/gi, '<fps :user.sync="user" :city.sync="city" :auth.sync="auth" :fixprice.sync="fixprice"></fps><index-form :user.sync="user" :city.sync="city" :auth.sync="auth"/>').replace(/{form_avtourist}/gi, '<fps :user.sync="user" :city.sync="city" :auth.sync="auth" :fixprice.sync="fixprice"></fps><index-form :user.sync="user" :city.sync="city" :auth.sync="auth"/>').replace(/{form_sravni_osago}/gi, '<osago-form/>').replace(/{form_sravni_kasko}/gi, '<kasko-form/>').replace(/{form_sokr}/gi, '<fps :user.sync="user" :city.sync="city" :auth.sync="auth" :fixprice.sync="fixprice"></fps><index-form :user.sync="user" :city.sync="city" :auth.sync="auth"/>').replace(/{form_karta}/gi, '<texosmotr-form :user.sync="user" :city.sync="city" :auth.sync="auth"/>').replace(/{/gi, '').replace(/}/gi, '').replace(new RegExp('../../../../frontend/public', 'g'), '/static').replace(new RegExp('../../../frontend/public', 'g'), '/static').replace(new RegExp('../../frontend/public', 'g'), '/static').replace(new RegExp('../frontend/public', 'g'), '/static').replace(new RegExp('frontend/public', 'g'), '/static').replace(new RegExp('../../../../uploads', 'g'), '/static/uploads').replace(new RegExp('../../../uploads', 'g'), '/static/uploads').replace(new RegExp('../../uploads', 'g'), '/static/uploads').replace(new RegExp('../uploads', 'g'), '/static/uploads').replace(new RegExp('uploads', 'g'), '/static/uploads').replace(new RegExp('<a', 'g'), '<router-link').replace(new RegExp('</a>', 'g'), '</router-link>').replace(new RegExp('href', 'g'), 'to') + '</div>'
      })
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>
@import "../../assets/css/service.css";
.v-application a {
  color: inherit;
}
.service-shop__link {
  color: white!important;
}
</style>
