<template>
  <div class="notice" v-click-outside="removePopup">
    <div class="notice__wrap" v-if="!isInputCurrentCity">
      <div class="notice__title">{{title}}
        <p class="notice__subtitle">{{subtitle?subtitle:''}}</p>
      </div>
      <div class="btn__wrap" v-if="isPopup === 11">
        <div @click="currentCity" class="notice__btn" style="background-color: red">
          Не верно
        </div>
        <div @click="removePopup" class="notice__btn">
          Верно
        </div>
      </div>
      <div class="btn__wrap" v-if="isPopup !== 11">
        <div @click="removePopup" class="notice__btn">
          Ok
        </div>
      </div>
    </div>
    <div class="notice__wrap" v-if="isInputCurrentCity" @click="getCity">
      <div v-if="1===2" class="notice__title">Введите ваш город <br><br>
        <input v-on:keyup.enter="setClientCity" v-model="clientCity" type="text" class="notice__subtitle">
      </div>
      <v-autocomplete
          dense
          filled
          :items="clientCity"
          v-model="setCity"
      ></v-autocomplete>
      <div class="btn__wrap">
        <div v-if="1===2" @click="setClientCity" class="notice__btn">
          Ok
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
export default {
  name: 'notification',
  data () {
    return {
      isInputCurrentCity: false, // переключатель
      clientCity: [],
      setCity: ''
    }
  },
  props: {
    title: String,
    subtitle: String,
    isPopup: Number
  },
  emits: [
    'removePopup', 'setClientCity'
  ],
  methods: {
    removePopup () {
      setTimeout(() => {
        this.$emit('removePopup')
      }, 1000) // говорим родителю что надо закрыть окно
    },
    currentCity () {
       this.isInputCurrentCity = true // показываем поле ввода города и скрываем другие кнопки
    },
    setClientCity () {
      console.log(this.setCity)
      this.$emit('setClientCity', this.setCity) // передаем родителю новый город
    },
    getCity () {
      const formData = new FormData()
      formData.append('_type', 'query')
      formData.append('lang', 'ru')
      axios.post('https://crm.v-avtoservice.com/list/site-get-cities',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(res => this.clientCity = res.data)
      console.log(this.clientCity)
    }
  },
  mounted() {
    // console.log(this.getCity())
  }
}
</script>

<style lang="scss">
.notice {
  width: 320px;
  height: 100px;
  top: 50px;
  left: calc(50% - 160px);
  position: fixed;
  background: rgba(0,0,0, 0.7);
  box-shadow: 0 24px 38px rgba(0,0,0,0.2), 0 10px 20px rgba(0,0,0,0.2);
  z-index: 100;
  .notice__wrap {
    border: 1px solid rgba(0,0,0,0.1);
    background: white;
    position: relative;
    display: inline-block;
    padding: 20px;
    min-width: 320px;
    /*width: 70%;*/
    /*min-height: calc(60% + 100px);*/
    max-width: 500px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 5px;
    .notice__close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .notice__title {
      top: calc(10% + 50px);
      text-align: center;
      font-size: calc( (100vw - 320px)/(1920 - 320) * (20 - 5) + 14px);
      color: black;
      margin: 0 10px 30px 10px;
      font-weight: 600;
      p {
        font-size: calc( (100vw - 320px)/(1920 - 320) * (14 - 5) + 12px);;
        font-weight: initial;
      }
      input {
        width: 350px;
        padding: 5px;
        border-radius: 2px;
        margin: 10px;
        border: 1px dashed rgba(0, 255, 93, 0.5);
        &:focus {
          outline:none;
          border: initial;
          border-bottom: 2px dashed rgba(0, 255, 93, 0.5);
        }
      }
    }
    .btn__wrap {
      display: flex;
      justify-content: space-between;
      .notice__btn {
        width: 40%;
        margin: 0 auto;
        background-color: #0F8831;
        color: black;
        text-align: center;
        border-radius: 4px;
        padding: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>
