<template>
    <v-carousel
        cycle
        height="auto"
        hide-delimiter-background
        show-arrows-on-hover
>
    <template v-slot:prev="{ on, attrs }">
        <v-btn
                color="success"
                v-bind="attrs"
                v-on="on"
                class="carousel-btn"
        >Предыдущий</v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
        <v-btn
                color="info"
                v-bind="attrs"
                v-on="on"
                class="carousel-btn"
        >Следующий</v-btn>
    </template>
    <v-carousel-item v-for="(com, i) in comment" :key="i">
        <div class="comment__chapter owl-carousel owl-theme" id="commentCarousel">
            <div class="comment__wrapper comment-one">
                <header class="comment__header">
                    <h3 class="comment__name"><span>От</span>{{com.name}}</h3>
                    <div class="comment__stars">
                        <div class="comment__stars-small">{{com.rating}}<img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14"></div>
                        <div class="comment__stars-big">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/star.svg" alt="star" width="14" height="14">
                            <img src="../assets/../assets/images/starempty.svg" alt="star" width="14" height="14">
                        </div>
                    </div>
                    <div class="comment__date">{{com.date}}</div>
                </header>
                <ul class="comment__list">
                    <li class="comment__item">
                        <div class="comment__service">Авто:</div>
                        <div class="comment__brand">{{com.auto}}</div>
                    </li>
                    <li class="comment__item">
                        <div class="comment__service">Сервис:</div>
                        <div class="comment__brand">{{com.auto_service}}</div>
                    </li>
                    <li class="comment__item">
                        <div class="comment__service">Услуга:</div>
                        <div class="comment__brand">{{com.service}} <span v-if="1===2"><img src="../assets/../assets/images/comment-arrow.svg" alt="arrow" width="11" height="6"></span></div>
                    </li>
                </ul>
                <section class="comment__text">
                    <h2 class="visually-hidden">Отзыв</h2>
                    <p>{{com.review}}<button v-if="1===2" class="comment__open">читать всё</button><span>для визуально-слухового восприятия</span></p>
                </section>
                <div class="comment__audio audio">
                    <div class="audio__icon"></div>
                    <div class="audio__line"></div>
                    <p class="audio__time">0:15 / 0:52</p>
                </div>
            </div>
        </div>
    </v-carousel-item>
</v-carousel>
</template>

<script>
    export default {
        name: "Carousel",
        data () {
            return {
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4',
                ],
                slides: [
                    'First',
                    'Second',
                    'Third',
                    'Fourth',
                    'Fifth',
                ],
              comment: [
                {
                  name: 'Евгений Грибоедов',
                  rating: 5,
                  date: '06-02-2022 15:02',
                  auto: 'Lexus RX 300',
                  auto_service: 'Авто Моторз на Тишинке',
                  service: 'Полировка авто',
                  review: 'Удобство поиска через “В-Автосервис” оценил еще две недели назад, когда оставлял заявку на диагностику ходовой части своего Lexus RX 300. Скажем так, в этот раз на поиск сервиса вместо заявленных 7 минут ушло почти полчаса, но учитывая то, что в итоге абразивная полировка мелких царапин мне обошлась на 60% дешевле, чем в автосервисе по соседству с домом, задержку в 20 минут считаю мелочью.'
                },
                {
                  name: 'Николай Смирнов',
                  rating: 5,
                  date: '10-02-2022 11:33',
                  auto: 'Volkswagen Golf V',
                  auto_service: 'Авто Моторз на Тишинке',
                  service: 'Шиномонтаж',
                  review: 'Спасибо за вашу работу! Оценил преимущество когда не нужно записываться заранее и уж тем более стоять в очередях. В самый разгар сезона смены резины с летней на зимнюю очереди в шиномонтажах огромные. Я записался через сайт за 5 минут рядом с работой на удобное время. Приехав в сервис пришлось пару минут подождать. В то же самое время очередь на шиномонтаж была человек 5.'
                },
                {
                  name: 'Владимир Мартынов',
                  rating: 5,
                  date: '18-01-2022 16:22',
                  auto: 'Range Rover Sport',
                  auto_service: 'Авто Моторз на Тишинке',
                  service: 'Замена фильтра',
                  review: 'Range Rover Sport. Замена салонного фильтра у официального дилера почти 6000 рублей. Через «В-Автосервис» мне подобрали специализированную станцию не официального дилера, которые сделали замену всего за 1200 рублей вместе со стоимостью фильтра. За 15 минут. Более чем доволен!'
                },
                {
                  name: 'Марк Добров',
                  rating: 5,
                  date: '11-02-2022 13:58',
                  auto: 'Fiat Doblo',
                  auto_service: 'Авто Моторз на Тишинке',
                  service: 'Замена колодок',
                  review: 'Долго подбирали сервис это единственный минус. Ссылались на то, что нет нужных не оригинальных колодок. Но зато сама замена передних колодок по очень низкой цене. Проверил стоимость колодок – оказалось дешевле чем на самом популярном сайте. Приятно. Рекомендую ускорить подбор сервисов и все будет гуд. '
                },
                {
                  name: 'Павел Должанов',
                  rating: 5,
                  date: '15-01-2022 9:45',
                  auto: 'KIA Sportage',
                  auto_service: 'Авто Моторз на Тишинке',
                  service: 'Замена колодок',
                  review: 'Лампочки купил сам, но поменять не смог. Такое впечатление что полмашины нужно разобрать. Сервис подобрали быстро. 300 рублей за все и 15 минут времени. Очень удобно что без очереди.'
                },
                {
                  name: 'Давид Петров',
                  rating: 5,
                  date: '16-02-2022 12:24',
                  auto: 'Kia Rio',
                  auto_service: 'Авто Моторз на Тишинке',
                  service: 'Химчистка салона',
                  review: 'Поразила стоимость, если честно. На 1000 дешевле чем у всех и чем в самом сервисе. В целом по качеству вопросов нет, по времени проведения тоже. Можно было бы не тратить время на прозвоны всех моек и сразу обратиться В-Автосервис.рф '
                },
                {
                  name: 'Елена Фоменко',
                  rating: 5,
                  date: '25-02-2022 16:58',
                  auto: 'KIA Picanto',
                  auto_service: 'Авто Моторз на Тишинке',
                  service: 'Полировка авто',
                  review: 'Признаться, до недавнего времени не до конца понимала, как работает эта система. Но дверку царапнули прямо накануне свадьбы сестры, и времени на раздумья и самостоятельный поиск сервиса не было, поэтому решила оставить заявку на сайте. Сервис нашли совсем рядом, и самое главное - отполировать дверь мне предложили вне очереди, объяснив это, как “привилегию клиентам В-Автосервиса”. Не смотря на то, что в сервис приехала раньше оговоренного времени, мастер приемщик без проблем принял авто в работу. Уже как минимум по этой причине буду рекомендовать друзьям.'
                },
                {
                  name: 'Андрей Быковский',
                  rating: 5,
                  date: '05-02-2022 11:31',
                  auto: 'Nissan X-Trail T32',
                  auto_service: 'Авто Моторз на Тишинке',
                  service: 'Полировка авто',
                  review: 'Порадовало то, что при заявке сразу указали точную стоимость полировки авто. Обычно звонишь в сервис, по телефону оглашают одну сумму, на деле отдельно доплачиваешь за полировку фар, отдельно за подкраску. А тут еще и чернение резины сделали в общую стоимость.'
                },
              ]
            }
        },
    }
</script>

<style scoped>
    .carousel-btn {
    }
</style>
