import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions/actions'
import mutations from './mutations/mutations'
import getters from './getters/getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    displayName: null,
    email: null,
    emailVerified: null,
    isAnonymous: null,
    metadata: null,
    phoneNumber: null,
    photoURL: null,
    uid: null,
  },
  mutations,
  getters,
  actions
})
export default store
