<template>
    <div>
        <v-card-text>
            <v-autocomplete
                    v-if="isBrand"
                    :items="brand"
                    :filter="customFilter"
                    color="white"
                    item-text="name"
                    label="Поиск по марке"
                    placeholder="Начинайте вводить... например Ford"
                    hide-details
                    hide-selected
                    :loading="loading"
            >
                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-title>
                            Ничего не найдено...
                            <strong>попробуйте еще раз</strong>
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-autocomplete>
            <v-autocomplete
                    v-if="isService"
                    :items="service"
                    :filter="customFilter"
                    color="white"
                    item-text="name"
                    label="Поиск по сервису"
                    placeholder="Начинайте вводить... например тюнинг"
                    hide-details
                    hide-selected
                    :loading="loading"
            >
                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-title>
                            Ничего не найдено...
                            <strong>попробуйте еще раз</strong>
                        </v-list-item-title>
                    </v-list-item>
                </template>
              <template v-slot:item="{ attr, on, item, selected }">
                <router-link :to="{name: 'Service', params: {name: item.name}}">
                  <v-list-item-content
                    v-bind="attr"
                    :input-value="selected"
                    v-on="on"
                    style="width: 100%"
                  >
                    <p>{{item.name}}</p>
                  </v-list-item-content>
                </router-link>
              </template>
            </v-autocomplete>
        </v-card-text>
        <v-snackbar
                v-model="hasSaved"
                :timeout="2000"
                absolute
                bottom
                left
        >
            Your profile has been updated
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        name: "Search",
        props: [ 'isService', 'isBrand' ],
        data () {
            return {
                hasSaved: false,
                isEditing: null,
                model: null,
                loading: false,
                brand: [
                    { name: 'Aston Martin', abbr: 'AS', id: 2 },
                    { name: 'Audi', abbr: 'AU', id: 3 },
                    { name: 'Bentley', abbr: 'BE', id: 4 },
                    { name: 'BMW', abbr: 'BM', id: 5 },
                    { name: 'Brilliance', abbr: 'BR', id: 6 },
                    { name: 'УАЗ', abbr: 'УА', id: 7 },
                    { name: 'Cadillac',abbr: 'CA', id: 2 },
                    { name: 'Changan',abbr: 'CHA', id: 2 },
                    { name: 'Chery',abbr: '', id: 2 },
                    { name: 'Chevrolet',abbr: 'CHE', id: 2 },
                    { name: 'Chrysler',abbr: 'Ch', id: 2 },
                    { name: 'Citroen',abbr: 'Cit', id: 2 },
                    { name: 'Datsun',abbr: 'Da', id: 2 },
                    { name: 'DongFeng',abbr: 'DO', id: 2 },
                    { name: 'DS',abbr: 'DS', id: 2 },
                    { name: 'FAW',abbr: 'FA', id: 2 },
                    { name: 'Ferrari',abbr: 'FE', id: 2 },
                    { name: 'Fiat', abbr: 'FI', id: 2 },
                    { name: 'Ford', abbr: 'Fo', id: 2 },
                    { name: 'Foton', abbr: 'Fo', id: 2 },
                    { name: 'Geely', abbr: 'Ge', id: 2 },
                    { name: 'Genesis', abbr: 'Ge', id: 2 },
                    { name: 'Great Wall', abbr: 'Gr', id: 2 },
                    { name: 'Haval', abbr: 'HA', id: 2 },
                    { name: 'Hawtai', abbr: 'HAW', id: 2 },
                    { name: 'Honda', abbr: 'HO', id: 2 },
                    { name: 'Hyundai', abbr: 'HY', id: 2 },
                    { name: 'Infiniti', abbr: 'IN', id: 2 },
                    { name: 'Isuzu', abbr: 'IS', id: 2 },
                    { name: 'JAC', abbr: 'JA', id: 2 },
                    { name: 'Jaguar', abbr: 'JA', id: 2 },
                    { name: 'Jeep', abbr: 'JE', id: 2 },
                    { name: 'Kia', abbr: 'KI', id: 2 },
                    { name: 'Lamborghini', abbr: 'LAM', id: 2 },
                    { name: 'Land Rover', abbr: 'LA', id: 2 },
                    { name: 'Lexus', abbr: 'LE', id: 2 },
                    { name: 'Lifan', abbr: 'LI', id: 2 },
                    { name: 'Maserati', abbr: 'MA', id: 2 },
                    { name: 'Mazda', abbr: 'MA', id: 2 },
                    { name: 'Mercedes-Benz', abbr: 'ME', id: 2 },
                    { name: 'Mini', abbr: 'MI', id: 2 },
                    { name: 'Mitsubishi', abbr: 'MIT', id: 2 },
                    { name: 'Nissan', abbr: 'NI', id: 2 },
                    { name: 'Peugeot', abbr: 'PE', id: 2 },
                    { name: 'Porsche', abbr: 'PO', id: 2 },
                    { name: 'Renault', abbr: 'REN', id: 2 },
                    { name: 'Rolls-Royce', abbr: 'RO', id: 2 },
                    { name: 'Skoda', abbr: 'SK', id: 2 },
                    { name: 'Smart', abbr: 'SM', id: 2 },
                    { name: 'SsangYong', abbr: 'SA', id: 2 },
                    { name: 'Subaru', abbr: 'SU', id: 2 },
                    { name: 'Suzuki', abbr: 'SUZ', id: 2 },
                    { name: 'Toyota', abbr: 'TOY', id: 2 },
                    { name: 'Volkswagen', abbr: 'VOL', id: 2 },
                    { name: 'Volvo', abbr: 'VO', id: 2 },
                    { name: 'Zotye', abbr: 'ZO', id: 2 },
                    { name: 'ВАЗ (Lada)', abbr: 'LAВА', id: 2 },
                    { name: 'ГАЗ', abbr: 'ГА', id: 2 },
                ],
                service: [
                    { name: 'Диагностика', abbr: 'Ди' },
                    { name: 'Техническое обслуживание', abbr: 'Тех' },
                    { name: 'Проверка сход/развала', abbr: 'развал' },
                    { name: 'Кузовные работы', abbr: 'кузов' },
                    { name: 'Трансмиссия', abbr: 'Трансм' },
                    { name: 'Услуги с выездом', abbr: 'выезд' },
                    { name: 'Подвеска', abbr: 'Подвес' },
                    { name: 'Защитные пленки', abbr: 'пленк' },
                    { name: 'Изготовление дубликатов ключей', abbr: 'ключ' },
                    { name: 'Стекла, фары', abbr: 'Стекл' },
                    { name: 'Мойка, химчистка', abbr: 'Мойка' },
                    { name: 'Тюнинг', abbr: 'Тюнинг' },
                    { name: 'Тормозная система', abbr: 'Тормоз' },
                    { name: 'Сложный агрегатный ремонт', abbr: 'агрег' },
                    { name: 'Мотосервис', abbr: 'Мото' },
                    { name: 'Электрика', abbr: 'Элек' },
                    { name: 'Тонировка', abbr: 'Тон' },
                    { name: 'Эвакуатор', abbr: 'Эвак' },
                    { name: 'Дополнительное оборудование', abbr: 'Доп' },
                    { name: 'Шиномонтаж', abbr: 'Шин' },
                    { name: 'Автострахование', abbr: 'страх' },
                    { name: 'Масло, жидкости, фильтры', abbr: 'Масло' },
                    { name: 'Автоюрист', abbr: 'Автоюрист' },
                    { name: 'Нужна консультация?', abbr: 'консул' },
                    { name: 'Полировка, уход', abbr: 'Полир' },
                    { name: 'Замена АКБ', abbr: 'АКБ' }
              ],
            }
        },

        methods: {
            customFilter (item, queryText) {
                const textOne = item.name.toLowerCase()
                const textTwo = item.abbr.toLowerCase()
                const searchText = queryText.toLowerCase()

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1
            },
            save () {
                this.isEditing = !this.isEditing
                this.hasSaved = true
            },
        },
    }
</script>

<style>

.category__input input {
  border: none!important;
  border-bottom: 0px;
}
</style>
