// import axios from 'axios'
import {
  browserSessionPersistence,
  getAuth,
  onAuthStateChanged,
  signOut,
  setPersistence,
  signInWithEmailAndPassword
} from "firebase/auth";
import router from "../../router";
import firebase from "firebase/compat";

export default {
  // async LOGIN ({commit}, data) {
  //   try {
  //     const auth = getAuth();
  //     setPersistence(auth, browserSessionPersistence)
  //       .then(() => {
  //         // https://firebase.google.com/docs/auth/web/auth-state-persistence
  //         return signInWithEmailAndPassword(auth, data.login, data.pass).then(res => {
  //           commit('SET_USER_AUTH', res.user.uid)
  //           localStorage.user = res.user.uid
  //           router.replace('/panel-admin')
  //         })
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       });
  //   }
  //   catch (e) {
  //     return e
  //   }
  // },
  // async LOGOUT ({commit}) {
  //   await firebase.auth().signOut()
  //   commit('LOGOUT')
  // }
  async LOGIN ({commit}, user) {
    let userdata = {
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      metadata: user.metadata,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      uid: user.uid,
    }
    localStorage.userdata = JSON.stringify(userdata)
    commit('AUTH_CHECK', userdata)
  },
  async LOGOUT ({commit}) {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      commit('LOGOUT')
      console.log('Sign-out successful')
    }).catch((error) => {
      // An error happened.
      console.log('Sign-out error')
    });
  },
  async AUTH_CHECK ({commit}) {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log(user)
        let userdata = {
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
          metadata: user.metadata,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL,
          uid: user.uid,
        }
        localStorage.userdata = JSON.stringify(userdata)
        commit('AUTH_CHECK', userdata)
        // this.writeUserData(userdata)
        // ...
      } else {
        // User is signed out
        console.log('User is signed out')
        commit('AUTH_CHECK', null)
      }
    });
  }
}
