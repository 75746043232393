<template>
    <v-form v-model="valid" id="yaFrom">
        <v-container>
            <v-row>
                <v-col
                        cols="12"
                        md="4"
                >
                    <v-text-field
                            v-model="form.name"
                            :rules="nameRules"
                            :counter="10"
                            label="Имя"
                            required
                    ></v-text-field>
                </v-col>
                <v-col
                        cols="12"
                        md="4"
                >
                    <v-select
                            v-model="form.select"
                            :items="form.brands"
                            label="Марка"
                            :rules="selectBrands"
                            required
                    ></v-select>
                </v-col>
                <v-col
                        cols="12"
                        md="4"
                >
                    <v-text-field
                            v-model="form.model"
                            :rules="modelRules"
                            :counter="15"
                            label="Модель"
                            required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                            v-model="form.phone"
                            :counter="11"
                            :rules="phoneRules"
                            label="Телефон"
                            placeholder="89999999999"
                            required
                    ></v-text-field>
                </v-col>
                <v-col
                        cols="12"
                        md="6"
                >
                    <v-textarea
                            outlined
                            v-model="form.additional"
                            name="input-7-4"
                            label="Что нужно отремонтировать?"
                            placeholder="Не более 100 символов"
                            counter="100"
                            :rules="textareaRules"
                    ></v-textarea>
                </v-col>
                <v-col
                        cols="12"
                        md="8"
                >
                    <v-checkbox
                            v-model="checkbox"
                            value="1"
                            label="Я соглашаюсь с условиями обработки персональных данных"
                            type="checkbox"
                            required
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-btn
                    class="mr-4"
                    :disabled="!checkPolicy"
                    @click="submit"
            >
                Получить предложения СТО
            </v-btn>
        </v-container>
        <notice
                v-if="notice.isPopup === 1"
                @removePopup="removePopup"
                :isPopup="notice.isPopup"
                :title="notice.title"
                :subtitle="notice.subtitle"
        />
    </v-form>
</template>

<script>
    // import Inputmask from 'inputmask'
    import axios from "axios";
    import notice from './notification'
    export default {
        name: "Form",
        components: {
            notice
        },
        data: () => ({
            valid: false,
            form: {
                name: '',
                phone: '',
                model: '',
                brands: [
                    'Aston Martin',
                    'Audi',
                    'Bentley',
                    'BMW',
                    'Brilliance',
                    'Cadillac',
                    'Changan',
                    'Chery',
                    'Chevrolet',
                    'Chrysler',
                    'Citroen',
                    'Datsun',
                    'DongFeng',
                    'DS',
                    'FAW',
                    'Ferrari',
                    'Fiat',
                    'Ford',
                    'Foton',
                    'Geely',
                    'Genesis',
                    'Great Wall',
                    'Haval',
                    'Hawtai',
                    'Honda',
                    'Hyundai',
                    'Infiniti',
                    'Isuzu',
                    'JAC',
                    'Jaguar',
                    'Jeep',
                    'Kia',
                    'Lamborghini',
                    'Land Rover',
                    'Lexus',
                    'Lifan',
                    'Maserati',
                    'Mazda',
                    'Mercedes-Benz',
                    'Mini',
                    'Mitsubishi',
                    'Nissan',
                    'Peugeot',
                    'Porsche',
                    'Renault',
                    'Rolls-Royce',
                    'Skoda',
                    'Smart',
                    'SsangYong',
                    'Subaru',
                    'Suzuki',
                    'Toyota',
                    'Volkswagen',
                    'Volvo',
                    'Zotye',
                    'ВАЗ (Lada)',
                    'ГАЗ',
                    'УАЗ',
                ],
                select: '',
                additional: '',
            },
            notice: {
                isPopup: null,
                title: null,
                subtitle: null,
            },
            nameRules: [
                v => !!v || 'Имя обязательно',
                v => v.length <= 10 || 'Имя должно быть короче 10 символов.',
            ],
            modelRules: [
                v => !!v || 'Укажите модель',
                v => v.length <= 15 || 'Имя должно быть короче 10 символов.',
            ],
            textareaRules: [
                v => v.length <= 100 || 'Не более 100 символов',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            selectBrands: [
                v => !!v || 'Выберите марку',
            ],
            phoneRules: [
                value => !!value || 'Обязательное поле',
                value => (typeof value === "string") || 'Введите только цифры',
                value => (value && value.length >= 11) || 'Не менее 11 цифр',
                value => (value && value.length <= 11) || 'Не более 11 цифр',
            ],
            checkbox: null,
        }),
        computed: {
            checkPolicy: function () {
                return this.checkbox && this.valid
            }
        },
        methods: {
            submit(form) {
                console.log(form)
                this.notice.isPopup = 1
                this.notice.title = 'Заявка отправлена!'
                this.notice.subtitle = 'Ваша заявка успешно отправлена.'
                const formData = new FormData()
                formData.append('crm', '1')
                formData.append('pipe', '1')
                formData.append('contact[name]', this.form.name)
                formData.append('contact[3]', this.form.phone)
                formData.append('lead[36]', this.$route.params.clientCity)
                formData.append('lead[5]', this.form.select)
                formData.append('lead[6]', this.form.model)
                formData.append('lead[32]', 'в-автосервисе')
                formData.append('note', `Дополнительно: ${this.form.additional}<br>Сайт: в-автосервисе`)
                axios.post('https://crm.v-avtoservice.com/api/import-lead',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
            },
            removePopup () {
                this.notice.isPopup = false
            }
        },
        mounted() {
            // Inputmask().mask(document.querySelectorAll('input[type=text]'))
            //geo = navigator.geolocation
        }
    }
</script>
<style scoped>

</style>