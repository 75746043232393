<template>
  <div>
    <section class="offer">
      <div class="offer__wrapper">
        <div class="offer__text">
          <h2 class="offer__title">Подберём лучший автосервис <br> за 7 минут</h2>
          <p>Это бесплатно</p>
        </div>
        <table class="offer__table">
          <tbody>
          <tr><th>128</th><td>сервисов <br> готовы <br> предложить <br> свою цену</td></tr>
          <tr><th>94</th><td>количество <br> ремонтов</td></tr>
          <tr><th>3</th><td>ремонтируются <br> сейчас</td></tr>
          </tbody>
        </table>
      </div>
    </section>
    <section class="form-section">
      <div class="form-section__wrapper">
        <h2 class="section-title">Вашего автосервиса еще нет в нашем агрегаторе?</h2>
        <p class="connection__offer">
          Направляйте заявку прямо сейчас. Для Вас это бесплатно. <br>
          Мы работаем во всех городах России!
        </p>
        <form action="#" class="form connection__form">
          <div class="form__input-wrap">
            <div class="input__wrap"><input type="text" placeholder="Название сервиса"></div>
            <div class="input__wrap input__wrap-arrow">
              <input type="text" placeholder="Город" class="input__arrow">
              <span><img src="../../assets/images/arrow-form.svg" alt="arrow" width="12" height="6"></span>
            </div>
            <div class="input__wrap"><input type="text" placeholder="Веб-сайт"></div>
            <div class="input__wrap"><input type="text" placeholder="Ваше имя"></div>
            <div class="input__wrap"><input type="text" placeholder="+7 (___) ___-__-__"></div>
            <div class="input__wrap"><input type="text" placeholder="user@domain.com"></div>
          </div>
          <label class="agree__input">
            <input type="checkbox">
            <span class="agree__checkbox"></span>
            <span class="agree__text">Я соглашаюсь с <span class="agree__blue">условиями обработки персональных данных</span></span>
          </label>
          <button class="form-btn connection__btn">Присоединиться</button>
        </form>
        <div class="connection__about">
          <div class="connection__video"><img src="../../assets/images/connection/connection-video.jpg" alt="video" width="300" height="169"></div>
          <div class="connection__story">
            <p class="connection__city">В-Автосервис работает в России и странах СНГ</p>
            <div class="connection__text">
              <div class="connection__text-wrapper">
                <div class="i-icon">i</div>
                <p><span>В-Автосервис</span> – признанный динамично развивающийся сервис ремонта автомобилей, работающий в России и странах СНГ.</p>
                <p>Мы сделали удобный и понятный для клиентов сайт, поэтому обеспечиваем большое количество заказов.</p>
                <p>Отдел маркетинга имеет прямые договоры с ведущими поисковыми системами и социальными сетями и за свой счет обеспечивает рекламу Вашего сервиса.</p>
                <p>ГОТОВЬТЕ МАСТЕРОВ! КОЛИЧЕСТВО ЗАКАЗОВ УЖЕ СКОРО СТАНЕТ БОЛЬШЕ!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="connection__process">
          <h2 class="section-title">Как это работает</h2>
          <div class="connection__list">
            <div class="connection__item">
              <span class="connection__number">1</span>
              <div class="connection__work">Подключитесь к нашему агрегатору за 1 минуту. Просто определите, какой % Вы готовы платить нам за каждого клиента, которого мы запишем на ремонт в Вашу СТО.</div>
            </div>
            <div class="connection__item">
              <span class="connection__number">2</span>
              <div class="connection__work">Мы получаем очень много заявок на ремонт.</div>
            </div>
            <div class="connection__item">
              <span class="connection__number">3</span>
              <div class="connection__work">Заявки по Вашей тематике попадают в Ваш личный кабинет. Вы можете отвечать на них, предлагать свою стоимость.</div>
            </div>
            <div class="connection__item">
              <span class="connection__number">4</span>
              <div class="connection__work">Клиент выбирает СТО, условия которого ему подошли.</div>
            </div>
            <div class="connection__item">
              <span class="connection__number">5</span>
              <div class="connection__work">Автовладелец платит за ремонт непосредственно Вам. Никакой предоплаты мы не берем!</div>
            </div>
            <div class="connection__item">
              <span class="connection__number">6</span>
              <div class="connection__work">Ваш сервис выполнил ремонт и получил от клиента деньги – Вы просто платите нашу комиссию.</div>
            </div>
          </div>
          <div class="connection__process-btn">Подключиться</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Connect-autoservice"
}
</script>

<style scoped>
@import "../../assets/css/connection.css";

</style>
