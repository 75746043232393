function clearState (state) {
  state.displayName = null
  state.email = null
  state.emailVerified = null
  state.isAnonymous = null
  state.metadata = null
  state.phoneNumber = null
  state.photoURL = null
  state.uid = null
}

export default {
  AUTH_CHECK: (state, userdata) => {
    if (userdata) {
      state.displayName = userdata.displayName
      state.email = userdata.email
      state.emailVerified = userdata.emailVerified
      state.isAnonymous = userdata.isAnonymous
      state.metadata = userdata.metadata
      state.phoneNumber = userdata.phoneNumber
      state.photoURL = userdata.photoURL
      state.uid = userdata.uid
    } else {
      clearState(state)
    }
  },
  LOGOUT: (state) => {
    clearState(state)
  },
}
