<template>
  <v-container>
    <section class="breadcrumbs" v-if="$route.path !== '/'">
      <ul class="breadcrumbs__list">
        <li class="breadcrumbs__item"><router-link to="/">Главная</router-link></li>
        <li class="breadcrumbs__item"><a href="#">{{$route.params.name || $route.name || 'Диагностическая карта'}}</a></li>
      </ul>
    </section>
  </v-container>
</template>

<script>
  export default {
    name: "Breadcrumbs"
  }
</script>

<style scoped>

</style>